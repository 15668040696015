.login_page {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to top, #0893bd, transparent, transparent),
    linear-gradient(to bottom, rgba(29, 27, 85, 0.35), transparent),
    url("../../assets/image/Rectangle\ 524.png");
  min-height: 77.3vh;
  max-height: max-content;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
}
