.lazy_loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: relative;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #a934db; /* Blue */
  border-radius: 50%;
  width: 160px;
  height: 160px;
  animation: spin 2s linear infinite;
  position: relative;
}

.lazy_image {
  position: absolute;
}

.why_choose_us {
  width: 100%;
  height: 100%;
  object-fit: 100% 100%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
