@font-face {
  font-family: "euclid";
  src: local("euclid"),
    url("../assets/fonts/Euclid\ Circular\ A\ Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclidbold";
  src: local("euclidbold"),
    url("../assets/fonts/Euclid\ Circular\ A\ Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "euclidsemibold";
  src: local("euclidsemibold"),
    url("../assets/fonts/Euclid\ Circular\ A\ SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avenir";
  src: local("avenir"),
    url("../assets/fonts/AvenirLTStd-Black.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
}

:root {
  --font-size: 1.5rem;
  --primary-lite: #0095c0;
  --secondary-lite: #3e5caa;
  --main: s;
  --primary-dark: #231f54;
  --secondary-dark: #231f20;
  --primary-font: "euclid";
  --secondary-font: "avenir";
}
