.landing_page {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/home/Hero.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .landing_page {
    background-size: cover;
    background-position: center;
  }
}
