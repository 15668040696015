@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.button {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  background: var(--primary-dark);
  padding: 15px 40px;
  margin-inline: 5px;
  border-radius: 4px;
  font-weight: normal;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  font-family: var(--secondary-font);
}

.glow-button:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  background-color: var(--secondary-lite);
  box-shadow: 2px 8px 12px var(--primary-lite);
}

.euclidnormal {
  font-family: "euclid", sans-serif;
}

.euclidbold {
  font-family: "euclidbold", sans-serif;
}

.euclid {
  font-family: "euclidbold", sans-serif;
}

.euclidsemibold {
  font-family: "euclidsemibold", sans-serif;
}

button:hover {
  border: none !important;
}

.btn {
  border: 1.5px solid #3e5caa !important;
}

.btn:hover {
  border: 1px solid #3e5caa !important;
}

a {
  list-style-type: none;
}

a:hover {
  list-style-type: none;
  text-decoration: none !important;
  color: #fff;
}

/* Add this CSS to your stylesheet */
.scrollable-list {
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  margin-bottom: 20px;
  max-height: 660px;
  /* Set max height for the list */
  overflow: scroll;
  /* Hide the scrollbar */
}

.scrollable-list-inner {
  overflow-y: hidden;
  /* Enable vertical scrolling */
}

.scrollable-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-list {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollable-list-item.hover:bg-gray-100:hover {
  background-color: #f5f5f5;
}

.loginLoader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  /* Manage a Height and Width Loader */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.contracts_listing {
  max-height: 80vh;
  /* Set a fixed height for the div */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

/* Hide the scrollbar in WebKit browsers */
.contracts_listing::-webkit-scrollbar {
  width: 0;
  /* Set the width of the scrollbar to 0 */
}

.table-container::-webkit-scrollbar {
  width: 0;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.table-container {
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.scrollbar-color-secondaryl::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbar-color-secondaryl::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

.scrollbar-color-secondaryl::-webkit-scrollbar-thumb {
  background: #3e5ca9; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

/* Add Pen Cusror for Verify Boardmember */

/* Example CSS with a data URL for a pen icon */
/* Example CSS with a data URL for the IconBxsPencil */
.pen-cursor {
  cursor: url("./assets/images/pen-icon.png") 16 16, auto;
}

/*
/* Custome list type */
.custom-list {
  counter-reset: list-counter;
  list-style-type: none;
  /* Remove default list styling */
}

.custom-list li {
  counter-increment: list-counter;
  padding-left: 32px;
  position: relative;
}

.custom-list li::before {
  content: "(" counter(list-counter, lower-alpha) ")";
  position: absolute;
  left: 0;
}

.custom-list ol {
  counter-reset: sublist-counter;
  /* Reset the counter for nested list */
  list-style-type: none;
  /* Remove default list styling for nested list */
}

.custom-list ol li {
  counter-increment: sublist-counter;
  /* Increment the counter for nested list */
}

.custom-list ol li::before {
  content: "(" counter(sublist-counter, lower-roman) ")";
  position: absolute;
  left: 0;
}

.custom-list ol ol {
  counter-reset: subsublist-counter;
  /* Reset the counter for nested list */
  list-style-type: none;
  /* Remove default list styling for nested list */
}

.custom-list ol ol li {
  counter-increment: subsublist-counter;
  /* Increment the counter for nested list */
  padding-left: 32px;
  position: relative;
}

.custom-list ol ol li::before {
  content: "(" counter(subsublist-counter, upper-alpha) ")";
}

/* Hide the default date picker icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.spin-loader {
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #3e5ca9 #ffffff00;
  animation: l1 1s infinite;
}
@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}

.booked-date {
  background-color: rgb(9, 135, 174) !important;
  color: white;
}

.react-pdf__Page__canvas,
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  width: 100% !important;
  height: 100% !important;
}

.suggestion:hover #suggestionIcon {
  fill: white;
}
.activeListing:hover #activeListingIcon,
.activeListing:focus-visible #activeListingIcon {
  fill: white;
}

.message-hover:hover .message-count {
  color: #0095c0 !important;
  background-color: white;
}

.ant-picker {
  box-shadow: none !important;
}

.ant-picker-input input {
  font-size: 1rem !important;
}

.ant-picker-input-placeholder {
  color: #0095c0 !important;
}

.ant-picker-active-bar {
  background: #0095c0 !important;
  height: 1.5px !important;
}

.ant-picker-range-separator {
  visibility: hidden !important;
}
.ant-picker-ok button {
  padding: 1rem;
  color: white !important;
  background: #0095c0 !important;
}

.ant-picker-cell-range-start div,
.ant-picker-cell-range-end div,
.ant-picker-time-panel-cell-selected div {
  background-color: #0095c0 !important;
  color: white !important;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  display: none !important;
}
