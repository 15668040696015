/* Pagination Container */
.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow items to wrap */
  list-style: none;
  padding: 0;
  margin: 1rem 0; /* Add some margin for spacing */
}

/* Pagination Item */
.pagination li {
  margin: 0.5rem 0.5rem;
}

/* Pagination Link */
.pagination a {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  color:#3F5CA9;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  display: flex;
  align-items: center; /* Center text vertically */
}

.pagination a:hover {
  background: linear-gradient(180deg, #0094BF 0%, #3E5CA9 100%);
  color: #ffffff;
  border: 1px solid #0094BF;
}

/* Active Pagination Link */
.pagination .active a {
  background: linear-gradient(180deg, #0094BF 0%, #3E5CA9 100%);
  color: #ffffff;
  border: 1px solid #0094BF;
}

/* Disabled Pagination Link */
.pagination .disabled a {
  cursor: default;
  opacity: 0.5;
}

.pagination .previous a,
.pagination .next a {
  border : none;
   padding: 0.5rem 1.5rem; /* Slightly larger padding for next/prev */
}
